import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Card,
  SolutionLayout
} from "../components/solutions-layout"
import { TalkToUs } from "../components/talk-to-us"

export default function LoanManagement() {
  return (
    <Layout tcoreinsight={false}>
      <SEO
        title={"Online loan management system | Touchcore Technology Nigeria"}
        description={
          "Online loan management system for microfinance and lending companies. Manage loans, disbursements, repayments, and savings accounts of your borrowers."
        }
      />
      <SolutionLayout
        data={{
          slug: "loan-x",
          hero: {
            image: require("./../assets/loan-mockup.png"),
            titleFirst: "",
            titleSecond: "LoanX",
            subtitle:
              "Cloud based loan management system that is secure and easy to use.",
            content:
              "LoanPro is a pre- built loan management software that is designed to automate end to end loan processes to ensure greater accuracy, productivity, and reliability in your business.",
          },
          section: {
            title: "How can LoanX help you",
            subtitle: (
              <>
                LoanX helps you manage all your customer loans and repayments
                from a simple to use dashboard.
                <br /> Spend less time running your business and more time
                growing your business.
              </>
            ),
          },
          imageSection1: {
            overlap: true,
            image: require("./../assets/BVN-sign-up4.png"),
            title: "On-board customer easily using their BVN data.",
            content:
              "Enhance your registration process, and relationship with customers by bringing them on board in just few clicks using their biometric verification number.",
          },
          imageSection2: {
            overlap: true,
            image: require("./../assets/loan-dashboard.png"),
            title: "A simple dashboard for loan management",
            content:
              "Access all the information about a customer, their loans, the disbursement, the loan repayment and transaction history from a simple to use dashboard.",
          },
          information: [
            {
              title: "Easily manage existing loans",
              content:
                "View loan details including repayments, loan terms, loan schedule, collateral, files, and comments.You can also print loan statements and schedule for your borrowers.Set loan fees, grace period, and penalties.",
            },
            {
              title: "Manage Disbursements",
              content:
                "With LoanX, you can easily disburse loans to customers in few simple clicks.",
            },
            {
              title: "Manage repayments easily",
              content:
                "Easily record customer loan repayments in a few clicks.It’s so easy to make repayments by your team or customer on the portal. Customer’s balance is updated instantly.",
            },
            {
              title: "Branch & Staff Management",
              content:
                "You can add new branches and assign them to your staff.You can also give them roles such as inputter, supervisor or branch manager.You can set permissions for each staff role and control what pages they can see and the branches they can operate in the admin area.",
            },
            {
              title: "Credit Score Service",
              content:
                "Simply check your customer’s credit score from the credit bureau and instantly know if they qualify for the loan. Check who else they are owing from the report.",
            },
            {
              title: "Automated repayments",
              content:
                "Automate the way your customers repay you by setting up direct debit instructions on their debit card.Customers can easily repay their loans from the comfort of their homes using their debit cards or bank transfer.Their accounts are updated instantly.No need for transfer slip.",
            },
          ],
          more_information: <ChartAndReports />,
        }}
      />
    </Layout>
  )
}

function ChartAndReports() {
  return (
    <>
      <div className="container pt-10 px-5 mx-auto md:px-20 mb-16 space-y-10">
        <img src={require("../assets/loan-chart.svg")} alt="" />
        <Card
          v={{
            title: "Charts and Reports",
            content:
              "An integrated automated reporting system to generate all business and financial reports in seconds. View detailed charts and see how your loan released and collections are changing month to month. You can also view cash flow reports and profit/loss statements.",
          }}
        />
      </div>
      <TalkToUs slug={"loan-x"} />
    </>
  )
}
